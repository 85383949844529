import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import app, { auth } from "src/Firebase";
import { Roles } from "src/utils/Constants";
import {
  getComplex,
  getRemoteModules,
  getRemoteUsers,
  getRemoteUnits,
} from "./complexSlice";

export const signIn = createAsyncThunk(
  "user/signIn",
  async ({ email, password, enqueueSnackbar }, { dispatch }) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      dispatch(setUser({ email: user.email, enqueueSnackbar }));

      return {};
    } catch (error) {
      enqueueSnackbar("Usuario o contraseña incorrectos", { variant: "error" });
      return error;
    }
  }
);

export const signOut = createAsyncThunk("user/singOut", async () => {
  await auth.signOut();
});

export const setUser = createAsyncThunk(
  "user/setData",
  async ({ email, enqueueSnackbar }, { dispatch }) => {
    const { docs } = await app
      .firestore()
      .collection("usuarios")
      .where("Correo", "==", email)
      .get();
    const data = { ...docs[0].data(), id: docs[0].id };
    const roles = data.Roles;
    if (
      (!roles?.superAdministrador &&
        !roles?.administrador &&
        !roles?.supervisor &&
        !roles?.parkey &&
        !roles?.empresa) ||
      data?.isActive === false
    ) {
      dispatch(signOut());
      enqueueSnackbar("¡No tienes permisos para acceder!", {
        variant: "error",
      });
      return Error("Not permissions");
    }

    const complexs =
      data.ConjuntoUid?.map((conjunto) => JSON.parse(conjunto)) || [];
    const idcomplex = complexs[0]?.uid || data.ConjuntoUidResidencia;
    dispatch(getComplex(idcomplex));
    dispatch(getRemoteModules(idcomplex));
    dispatch(getRemoteUsers(idcomplex));
    dispatch(getRemoteUnits(idcomplex));

    return {
      id: data.id,
      name: data.Nombre,
      lastName: data.Apellido,
      email: data.Correo,
      phone: data.Telefono,
      identity: data.Cedula,
      roles: data.Roles,
      complexs,
      ConjuntoUidResidencia: data.ConjuntoUidResidencia,
      Empresa: data.Empresa || null,
    };
  }
);

export const authSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    isAuth: false,
    error: "",
    user: {},
    rol: "",
  },
  reducers: {
    changeRol: (state, { payload }) => {
      state.rol = payload;
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.isLoading = true;
    },
    [signIn.fulfilled]: (state) => {
      state.isAuth = true;
      state.error = "";
    },
    [signIn.rejected]: (state) => {
      state.error = "El usuario o la contraseña no son validos";
      state.isLoading = false;
    },
    [setUser.pending]: (state) => {
      state.isLoading = true;
    },
    [setUser.fulfilled]: (state, { payload }) => {
      // manejar caso en login pero no tiene ningún rol habil
      let rol = "";
      const { roles = {} } = payload;
      if (roles?.superAdministrador) {
        rol = Roles.superadmin;
      } else if (roles?.administrador) {
        rol = Roles.admin;
      } else if (roles?.supervisor) {
        rol = Roles.supervisor;
      } else if (roles?.parkey) {
        rol = Roles.parkey;
      } else if (roles?.empresa) {
        rol = Roles.company;
      }
      state.user = payload;
      state.rol = rol;
      state.isLoading = false;
    },
    [setUser.rejected]: (state) => {
      state.isAuth = false;
      state.rol = "";
      state.isLoading = false;
    },
    [signOut.pending]: (state) => {
      state.isLoading = true;
    },
    [signOut.fulfilled]: (state) => {
      state.isAuth = false;
      state.isLoading = false;
      state.user = {};
      state.rol = "";
    },
    [signOut.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const selectUser = (state) => state.auth.user;
export const selectRol = (state) => state.auth.rol;

export const { changeRol } = authSlice.actions;
export default authSlice.reducer;
