/* eslint-disable default-param-last */
import axios from 'axios';

export const FIREBASE_API_KEY = process.env.REACT_APP_FCM_API_KEY;

export const sendNotification = (title = '', body = '', tokens = [], payload) => {
  if (tokens.length < 1) return;
  const message = {
    registration_ids: tokens,
    notification: {
      title,
      body,
    },
  };
  if (payload) {
    message.data = payload;
  }
  const config = {
    headers: {
      Authorization: `key=${FIREBASE_API_KEY}`,
    },
  };
  axios.post('https://fcm.googleapis.com/fcm/send', message, config).catch(() => undefined);
};
