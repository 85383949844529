import { firestore as db } from "src/Firebase";

export const newUser = (uid, user) => {
  if (uid) {
    return db.collection("usuarios").doc(uid).set(user);
  }
  return db.collection("usuarios").add(user);
};

export const getUserByEmail = (email) =>
  db.collection("usuarios").where("Correo", "==", email).get();
export const updateUser = (data, documentoId) =>
  db.collection("usuarios").doc(documentoId).update(data);
export const updateUserActive = (data, documentoId) =>
  db.collection("usuarios").doc(documentoId).set(data);
export const updateListas = (conjuntoId, documentoId, data) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("lista")
    .doc(documentoId)
    .update(data);

export const updateUserFamiliares = (documentoId, data) =>
  db.collection("usuarios").doc(documentoId).update({
    Familia: data,
  });
export const updatecuentasPorCobrar = (data, conjuntoId, cuentasId) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("cuentasPorCobrar")
    .doc(cuentasId)
    .update({
      CasaUsuario: data.Casa,
      NombreUsuario: `${data.Nombre} ${data.Apellido}`,
    });
export const deleteUserbyID = (userId) =>
  db.collection("usuarios").doc(userId).delete();

export const getConjunto = (conjuntoId) =>
  db.collection("conjuntos").doc(conjuntoId).get();

export const getUserByConjunto = (observer, conjuntoID) =>
  db
    .collection("usuarios")
    .where("ConjuntoUidResidencia", "==", conjuntoID)
    .onSnapshot(observer);

export const validateEmailExists = (mail, conjuntoId) =>
  db
    .collection("usuarios")
    .where("ConjuntoUidResidencia", "==", conjuntoId)
    .where("Correo", "==", mail)
    .limit(1)
    .get();

export const validateDNIExists = (dni, conjuntoId) =>
  db
    .collection("usuarios")
    .where("ConjuntoUidResidencia", "==", conjuntoId)
    .where("Cedula", "==", dni)
    .limit(1)
    .get();

export const getHousingUnits = (conjuntoId, observer) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("unidadesHabitacionales")
    .onSnapshot(observer);

export const getListas = (conjuntoId, observer) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("lista")
    .onSnapshot(observer);

export const getCuentasCobrar = (conjuntoId, casa) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("cuentasPorCobrar")
    .where("CasaUsuario", "==", casa)
    .get();

export const updateHousingUnit = (conjuntoId, unitId, data) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("unidadesHabitacionales")
    .doc(unitId)
    .update(data);

export const newPagoPendiente = (conjuntoId, data) =>
  db
    .collection("conjuntos")
    .doc(conjuntoId)
    .collection("cuentasPorCobrar")
    .add({
      Nombre: data.Nombre,
      Valor: data.Valor * 1,
      FechaLimite: new Date(data.FechaLimite),
      FechaRegistro: new Date(),
      Descripcion: data.Descripcion,
      UsuarioId: data.UsuarioId,
      NombreUsuario: data.NombreUsuario,
      CasaUsuario: data.CasaUsuario,
      Rubro: data.Rubro,
      SubRubro: data.SubRubro,
      ValorOriginal: data.Valor * 1,
    });
export const updateUnidadHabitacional = (conjuntoUid, unidad, data) =>
  db
    .collection("conjuntos")
    .doc(conjuntoUid)
    .collection("unidadesHabitacionales")
    .doc(unidad)
    .update({
      UltimaExpensa: new Date(data),
    });

// eslint-disable-next-line default-param-last
export const updateFailedUpload = async (data = [], idConjunto) => {
  try {
    const batch = db.batch();
    data.forEach((item) => {
      const docRef = db
        .collection("conjuntos")
        .doc(idConjunto)
        .collection("unidadesHabitacionales")
        .doc(item.id);
      if (item.Propietario) {
        batch.update(docRef, {
          DatosPropietario: item.Fullname,
          IdPropietario: item.id_user,
        });
      } else {
        batch.update(docRef, {
          DatosInquilino: item.Fullname,
          IdInquilino: item.id_user,
          Rentada: true,
        });
      }
    });
    await batch.commit();
    // eslint-disable-next-line no-console
    console.log("finalizado");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
