import {
  Feedback, HelpOutline, Dashboard, Home,
  AddToQueue, People as PeopleIcon,
  QuestionAnswer as QuestionAnswerIcon
} from '@material-ui/icons';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import GroupIcon from '@material-ui/icons/Group';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ContactMailIcon from '@material-ui/icons/ContactMail';

export default {
  superAdministrador: [
    {
      title: 'Dashboard',
      icon: Dashboard,
      href: '/superAdmin/dashboard',
    },
    {
      title: 'Conjuntos',
      icon: Home,
      href: '/superAdmin/conjuntos',
    },
    {
      title: 'Empresas',
      icon: HomeWorkIcon,
      href: '/superAdmin/Company',
    },
    {
      title: 'TGLabs Usuarios',
      icon: ContactMailIcon,
      href: '/superAdmin/usertglabs',
    },
    {
      title: 'Administradores',
      icon: PeopleIcon,
      href: '/superAdmin/administradores',
    },
    {
      title: 'Splash',
      icon: AddToQueue,
      href: '/superAdmin/splash',
    },
    {
      title: 'AllPanicAlerts',
      icon: AddToQueue,
      href: '/superAdmin/allPanics',
    },
    {
      title: 'Feedback',
      icon: Feedback,
      items: [
        {
          title: 'Sugerencias',
          icon: Feedback,
          href: '/superAdmin/sugerencias',
        },
        {
          title: 'Info & Ayuda',
          icon: HelpOutline,
          href: '/superAdmin/infoApp',
        },
        {
          title: 'FAQ',
          icon: QuestionAnswerIcon,
          href: '/superAdmin/FAQ',
        },
      ],
    },
    {
      title: 'Parametros - Botón de pagos ',
      icon: LocalAtmIcon,
      href: '/superAdmin/parametros',
    },
  ],
  residente: [
    {
      title: 'Dashboard Residente',
      icon: Dashboard,
      href: '/residente/dashboard',
    },
  ],
  administrador: [
    {
      title: 'Dashboard Admin',
      icon: Dashboard,
      href: '/administrador/dashboard',
    }
  ],
  supervisor: [
    {
      title: 'Dashboard Supervisor',
      icon: Dashboard,
      href: '/supervisor/dashboard',
    },
  ],
  empresa: [
    {
      title: 'Dashboard',
      icon: Dashboard,
      href: '/empresa/dashboard',
    },
    {
      title: 'Conjuntos',
      icon: Home,
      href: '/empresa/conjuntos',
    },
    {
      title: 'Administración',
      icon: GroupIcon,
      href: '/empresa/administracion',
    },
  
  ]
}