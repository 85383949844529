import "moment/locale/es"
import 'src/utils/yup/yup.custom'
import {
  lazy, Suspense, useEffect,
  useState,
} from 'react';
import './App.css';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import GlobalStyles from './components/Common/GlobalStyles';
import LoadingScreen from './components/Common/LoadingScreen';
import ProtectedRoute from './components/Common/ProtecteRoute';

import SuperAdmin from './views/viewSuperAdministrador';
import RootEmpresa from "./views/viewEmpresa";
import RootParkey from "./views/viewParkey";
import Admin from './views/viewAdministrador';
import Guardia from './views/viewGuardia';

import { signOut } from './redux/slices/authSlice';
import { getCustomTheme } from "./redux/slices/themeSlice";
import { auth } from './Firebase';
import { Roles } from "./utils/Constants";
import { customTheme } from './theme';

const ViewLogin = lazy(() => import('./views/login'));
const Unauthorized = lazy(() => import('./views/errors/Unauthorized'));
const NotFoundView = lazy(() => import('./views/errors/NotFoundView'));

const Paths = [
  { path: '/administrador', roles: [Roles.admin], component: Admin },
  { path: '/superAdmin', roles: [Roles.superadmin], component: SuperAdmin },
  { path: '/empresa', roles: [Roles.company], component: RootEmpresa },
  { path: '/guardia', roles: [Roles.supervisor], component: Guardia },
  { path: '/parkey', roles: [Roles.parkey], component: RootParkey },

]

const App = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth)
  const companyTheme = useSelector(getCustomTheme)

  const [theme, setTheme] = useState(customTheme(companyTheme))

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) dispatch(signOut());
    });
    return unsubscribe;
  }, [dispatch, isAuth]);

  useEffect(() => {
    setTheme(
      customTheme(companyTheme)
    )
  }, [companyTheme])

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider dense maxSnack={3}>
          <Suspense fallback={<LoadingScreen />}>
            <Router>
              <GlobalStyles />
              <Switch>
                <Route path="/" exact render={(props) => <ViewLogin {...props} />} />
                {Paths.map(item => (
                  <ProtectedRoute
                    roles={item.roles}
                    path={item.path}
                    component={item.component}
                    key={item.path}
                  />
                ))}
                <Route
                  path="/unauthorized"
                  render={(props) => <Unauthorized {...props} />}
                />
                <Route path="/error" render={(props) => <NotFoundView {...props} />} />
                <Redirect to="/error" />
              </Switch>
            </Router>
          </Suspense>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
